import { render, staticRenderFns } from "./NavBar.vue?vue&type=template&id=1e801380"
import script from "./NavBar.vue?vue&type=script&lang=js"
export * from "./NavBar.vue?vue&type=script&lang=js"
import style0 from "./NavBar.vue?vue&type=style&index=0&id=1e801380&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonsNuxtImage: require('/app/components/Commons/NuxtImage.vue').default,CommonsLanguageSwitcher: require('/app/components/Commons/LanguageSwitcher.vue').default,CommonsThemeMode: require('/app/components/Commons/ThemeMode.vue').default,Theme2ComponentsExchangeRate: require('/app/components/Theme2/Components/ExchangeRate.vue').default})
