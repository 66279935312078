
import { mapState, mapGetters } from 'vuex'
export default {
    name: 'HoldBalanceDialogComponent',
    props: {
        isSocketTrick: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        dialog: false,
    }),
    computed: {
        ...mapGetters({
            maintenance: 'themes/settings/maintenance',
        }),
        ...mapState({
            onHoldWallet: (state) => state.player.onHoldWallet,
            player: (state) => state.player.player,
        }),
    },
    watch: {
        async isSocketTrick(val) {
            if (val) {
                await this.$store.dispatch('player/totalBalance')
                if (parseFloat(this.onHoldWallet) > parseFloat(0)) {
                    this.dialog = val
                }
            } else {
                this.dialog = val
            }
        },
    },
    async mounted() {
        await this.fetchOnHoldBalance()
    },
    methods: {
        async fetchOnHoldBalance() {
            await this.$store.dispatch('player/totalBalance')
            let cookieHoldBalance = this.$cookie.get('holdbl')
            if (!(cookieHoldBalance >= 0)) cookieHoldBalance = 0
            if (
                ~~this.onHoldWallet > 0 &&
                this.onHoldWallet.toString() !== cookieHoldBalance.toString()
            )
                this.dialog = true
            this.$cookie.set('holdbl', this.onHoldWallet)
        },
        closeOnHoldBalance() {
            this.$emit('close', true)
            this.dialog = false
        },
    },
}
