
export default {
    name: 'NuxtImage',
    props: {
        format: {
            type: String,
            default: null,
        },
        src: {
            type: String,
            default: null,
        },
        width: {
            type: String,
            default: null,
        },
        height: {
            type: String,
            default: null,
        },
        fit: {
            type: String,
            default: 'cover',
        },
        styles: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            failed_image: false,
        }
    },
    computed: {
        imageSource() {
            if (
                !this.src ||
                this.src === 'null' ||
                this.src.split('/')[this.src.split('/').length - 1] === 'null'
            )
                return '/no-image.jpeg'
            return this.failed_image ? '/no-image.jpeg' : this.src
        },
        computedProvider() {
            // Check if the source starts with "http" or is a relative/static path
            if (
                this.imageSource.startsWith('https://') ||
                this.imageSource.startsWith('http://')
            ) {
                return null // For external URLs, no provider is needed
            }
            return 'static' // For relative/static paths
        },
    },
    methods: {
        onImageError() {
            this.failed_image = true
        },
    },
}
